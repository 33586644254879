import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwPcLocations = (props) => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW PERFORMANCE CENTER LOCATIONS AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>How many BMW Performance Driving School locations are there?</h5>
                <p>We currently have three locations in California, South Carolina and Indianapolis. </p>
              </div>
              <div>
                <h5>Where is the BMW Performance Center located?</h5>
                <p>We currently have three locations in California, South Carolina and Indianapolis.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Indianapolis Motor Speedway</h5>
                <p>4790 W 16th St, Indianapolis, IN 46222<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Can I pick up a new BMW at the BMW Performance Center?</h5>
                <p>
                  Yes, we offer new vehicle delivery at our South Carolina location. 
                  Please visit our <a href="/delivery">Delivery page</a> and <a href="/deliveryfaq">
                  Delivery FAQ</a> for more information.
                </p>
              </div>
              <div>
                <h5>Does the BMW Performance Center offer classes at other locations?</h5>
                <p>
                  Each year, we travel abroad to famous locations such as the Nurburgring in Germany 
                  and the Arejeplog in Sweden for BMW Ice Training. Please <a href="tel:888-345-4269">
                  contact us</a> for more information about these programs.
                </p>
              </div>
              { props.page === 'full-day' ? (
                <div>
                  <h5>Is this a one-day driving class?</h5>
                  <p>
                    Yes. For two-day events, we offer M School and Advanced M School at our South Carolina and California locations.
                  </p>
                </div>
                ) : (
                  <>
                    <div>
                      <h5> How long is a half-day event?</h5>
                      <p>
                        The morning events run from 8 am-1 pm, and the afternoon events from 11:30am - 4:30pm, including lunch.
                      </p>
                    </div>
                    <div>
                    <h5>Is lunch Included?</h5>
                    <p>
                    Lunch is at the conclusion of the first session. For the afternoon session, lunch will begin at 11:30 am after registration.
                    </p>
                  </div>
                </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwPcLocations;