import React, { useRef, useEffect, useState } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tabs from "../components/tabs/Tabs";
import TabLink from "../components/tabs/TabLink";
import TabContent from "../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-light-accordion/demo/css/index.css";
import SchedulePopup from "../components/schedulepopup";

import Layout from "../components/mschool/layout";
import SEO from "../components/seo";
import ProInstructors from "../components/mschool/proinstructors";
import TopBannerIndy from "../components/mschool/topbanner-indy";
import settings from "../../settings";
import BmwPcLocations from "../components/bmwGlance/bmwPcLocations";


// schools
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/two-day-m-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/images/classes/race-license-class.jpg`;
const reacelicenseschool =
	`${settings.IMAGES_BASE_URL}/images/classes/advanced-m-school-banner%201.png`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/one-day-m-class.jpg`;
const m4gt4school =
	`${settings.IMAGES_BASE_URL}/images/classes/m4-gt4-class.jpg`;
const video =
	`${settings.IMAGES_BASE_URL}/videos/indy-home.mp4`;
const raceClock =
	`${settings.IMAGES_BASE_URL}/images/indy_images/race-clock.jpg`;
const fullDay =
	`${settings.IMAGES_BASE_URL}/images/indy_images/indy-full-day-like.jpg`;


// other
const dragrace =
	`${settings.IMAGES_BASE_URL}/images/indy_images/drag-race-slide.jpg`;
const hotlaps =
	`${settings.IMAGES_BASE_URL}/images/indy_images/hot-lap-slide.jpg`;
const leadfollow =
	`${settings.IMAGES_BASE_URL}/images/indy_images/04-lead-follow.jpg`;
const indyPoster =
	`${settings.IMAGES_BASE_URL}/images/indy_images/indy-bg.jpg`;
const hallowedGround =
	`${settings.IMAGES_BASE_URL}/images/indy_images/hallowed-ground.jpg`;
const mDriversClass = 
  `${settings.IMAGES_BASE_URL}/images/indy_images/m-drivers-class.jpg`;


const BMWM2 =
	`${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m2.jpg`;
const BMWM3 = `${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m3.jpg`;
const BMWM4 = `${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m4.jpg`;

const Agenda = () => {
	return (
		<SchedulePopup
			btnText="View Half-day Agenda"
			title="HALF-DAY M EXPERIENCE AT INDY"
			subtitle="Sample schedule subject to change."
		>
      <div  className="am_session">
        <div className="schedule__item">
          <p className="schedule__item__time">
            AM session <br /> 7:45 am - 8:30 am
          </p>
          <p className="schedule__item__event">Arrival, registration</p>
        </div>
        <div className="schedule__item">
          <p className="schedule__item__time">8:30 am - 9:00 am</p>
          <p className="schedule__item__event">Class discussion</p>
        </div>
        <div className="schedule__item">
          <p className="schedule__item__time">9:00 am - 12:00 pm</p>
          <p className="schedule__item__event">
            Lead/Follow on track <br />
            Timed Autocross <br />
            Drag Race <br />
            Hot Laps with instructor
          </p>
        </div>
        <div className="schedule__item">
          <p className="schedule__item__event">
            Concludes with lunch
          </p>
        </div>
      </div>
      <div  className="pm_session">
        <div className="schedule__item">
          <p className="schedule__item__time">
            PM Session <br />
            12:15 pm - 1:00 pm
          </p>
          <p className="schedule__item__event">Arrival, registration, lunch</p>
        </div>
        <div className="schedule__item">
          <p className="schedule__item__time">1:00 pm - 1:30 pm</p>
          <p className="schedule__item__event">Class discussion</p>
        </div>
        <div className="schedule__item">
          <p className="schedule__item__time">1:30 pm - 4:30 pm</p>
          <p className="schedule__item__event">
            Lead/Follow on track <br />
            Timed Autocross <br />
            Drag Race <br />
            Hot Laps with instructor
          </p>
        </div>
      </div>
		</SchedulePopup>
	);
};
const IndyHalfDay = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};

	const [width, setWidth] = useState(null);
	useEffect(() => {
		setWidth(window.innerWidth);
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [width, setWidth]);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const loactionsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
	return (
		<Layout>
			<SEO
				title="BMW Indy Half-Day School | BMW Performance Driving School"
				description="Learn to drive a BMW M car on the famous Indianapolis Motor Speedway with a half-day experience at the BMW M Track Series."
				keywords="bmw m school, m driving school, bmw m driving school, bmw m performance driving school, half-day m experience"
			/>
			<div className="indy__school">
        <TopBannerIndy
          url={video}
					image={indyPoster}
					mobileImage={indyPoster}
					header="BMW HALF-DAY M EXPERIENCE AT INDY"
					subheader="SPEEDWAY DRIVING THRILLS "
					pricing="FROM $650"
					text="It’s a half-day driving experience that’s full of fun at the Indianapolis Motor Speedway. You’ll experience multiple driving exercises on the famous track, and top it off with an unforgettable ride in a BMW M car."
					secondText="Dates now open for September and October 2025."
					buttonText="Book Now"
					butttonURL="https://pds.eventsbmw.com/?brand=M&date=2025-09-01&class=Half_Day_M_Experience_at_INDY"
					agendaPopup={<Agenda />}
          ariaLabel="indy-half-day"
				/>
				<section className="links__indy">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(loactionsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section ref={learnRef} className="learn__indy">
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Drag Race">
								<LazyLoadImage effect="blur" src={dragrace} alt="Drag Race" />
								<div className="accordion-content">
                  <h3>Face Off</h3>
                  <p>
                    Line up against another driver and time your launch. You’ll need to manage an M vehicle’s prodigious power off the line or risk losing a tenth of a second, and bragging rights.
                  </p>
								</div>
							</AccordionItem>
							<AccordionItem title="Lead/Follow">
								<LazyLoadImage
									effect="blur"
									src={leadfollow}
									alt="Lead/Follow"
								/>
								<div className="accordion-content">
									<h3>Follow the leader</h3>
									<p>
										You go where you look, and here you’ll be looking at our
										lead instructor’s car as they guide you around the proper
										line at ever-increasing speeds.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Timed Autocross">
								<LazyLoadImage
									effect="blur"
									src={raceClock}
									alt="Timed Autocross"
								/>
								<div className="accordion-content">
									<h3>Race the Clock</h3>
									<p>
										You’ll learn a lot here at Indy. Can you put it all together
										for timed runs against the other drivers? Smoothness is the
										key. Win the day and you’ll be Master of M.
									</p>
								</div>
							</AccordionItem>
              <AccordionItem title="Hot Laps">
								<LazyLoadImage
									effect="blur"
									src={hotlaps}
									alt="Hot Laps"
								/>
								<div className="accordion-content">
                  <h3>It’s show time</h3>
                  <p>
                    Our pro instructors will get behind the wheel of a BMW M car as you ride along for an incredible experience – one fast lap of Indy. Hold on tight.
                  </p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__indy__images">
									<TabContent for="drag-race" uniqueKey="1">
										<img src={dragrace} alt="Drag Race" />
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="2">
										<img
											src={leadfollow}
											alt="Lead Follow"
										/>
									</TabContent>
									<TabContent for="time-autocross" uniqueKey="3">
										<img
											src={raceClock}
											alt="Timed Autocross"
										/>
									</TabContent>
                  <TabContent for="hot-laps" uniqueKey="4">
										<img
											src={hotlaps}
											alt="Hot Laps"
										/>
									</TabContent>
								</div>
								<ul className="learn__indy__menu" role="tablist">
									<li role="none">
										<TabLink to="drag-race" uniqueKey="5">Drag Race</TabLink>
									</li>
									<li role="none">
										<TabLink to="lead-follow" uniqueKey="8">Lead/Follow</TabLink>
									</li>
									<li role="none">
										<TabLink to="time-autocross" uniqueKey="9">Timed Autocross</TabLink>
									</li>
									<li role="none">
										<TabLink to="hot-laps" uniqueKey="10">Hot Laps</TabLink>
									</li>
								</ul>
								<div className="learn__indy__content">
									<TabContent for="drag-race" uniqueKey="11">
										<h3>Face Off</h3>
										<p>
                      Line up against another driver and time your launch. You’ll need to manage an M vehicle’s prodigious power off the line or risk losing a tenth of a second, and bragging rights.
										</p>
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="12">
										<h3>Follow the leader</h3>
										<p>
											You go where you look, and here you’ll be looking at our
											lead instructor’s car as they guide you around the proper
											line at ever-increasing speeds.
										</p>
									</TabContent>
									<TabContent for="time-autocross" uniqueKey="13">
										<h3>Race the clock</h3>
										<p>
											You’ll learn a lot here at Indy. Can you put it all
											together for timed runs against the other drivers?
											Smoothness is the key. Win the day and you’ll be Master of
											M.
										</p>
									</TabContent>
                  <TabContent for="hot-laps" uniqueKey="14">
										<h3>It’s show time</h3>
										<p>
                      Our pro instructors will get behind the wheel of a BMW M car as you ride along for an incredible experience – one fast lap of Indy. Hold on tight.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<section
					ref={loactionsRef}
					className="locations__indy"
					style={{backgroundImage: `url(${hallowedGround})`,}}
				>
					<div className="container">
						<div className="locations__indy__content">
							<h3>Hallowed ground</h3>
							<h4>Indianapolis Motor Speedway</h4>
							<p>
								Home of so many iconic races, the Indianapolis Motor Speedway is
								the perfect backdrop to hone your driving skills. You’ll see
								some serious speed here.
							</p>
						</div>
					</div>
				</section>
				<section ref={powerRef} className="power__indy">
					<div className="container">
						<div className="striped-background">
							<div className="blue-stripe" />
							<div className="darkblue-stripe" />
							<div className="red-stripe" />
						</div>
						<div className="power__indy__header">
							<h2>POWER. POISE. PRECISION.</h2>
							<p>
                Each M car offers the same core of performance while bringing something unique to the table. 
                Experience them all during your speedway driving experience.
							</p>
              <p className="note">*Vehicles based on availability.</p>
						</div>
					</div>
					<div className="power__indy__content">
						<div className="row">
							<div className="power__indy__item">
								<LazyLoadImage
									effect="blur"
									src={BMWM2}
									alt="The BMW M2 Competition"
								/>
								<h3>The BMW M2</h3>
								<p>
                  The mighty M2 is our autocross king, and its agile handling abilities will have you smiling 
                  from ear to ear.
								</p>
							</div>
							<div className="power__indy__item">
								<LazyLoadImage effect="blur" src={BMWM3} alt="The BMW M3 Competition" />
								<h3>The BMW M3 Competition</h3>
								<p>
                  Four doors just means more fun. The BMW M3 will prove to you why its iconic name is revered 
                  throughout the motorsport world.
								</p>
							</div>
							<div className="power__indy__item">
								<LazyLoadImage
									effect="blur"
									src={BMWM4}
									alt="The BMW M4 Competition"
								/>
								<h3>The BMW M4 Competition</h3>
								<p>
                  A perfect balance of precision and power, the BMW M4 is an ace on the track with 503 horsepower 
                  on tap.
								</p>
							</div>
						</div>
					</div>
				</section>
				<ProInstructors ref={instructorsRef} />
				<section ref={classesRef} className="other__schools__indy">
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other__schools__indy__content row">
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool" aria-label="twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool" aria-label="advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={reacelicenseschool}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Feel the need for real speed.</p>
							<Link to="/mschool/racelicenseschool" aria-label="racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt=">One-Day M School"
							/>
							<h3>One-Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool" aria-label="onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4" aria-label="m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={fullDay}
								alt="M4 GT4 School"
							/>
							<h3>Full-Day M Intensive at Indy</h3>
							<p>A full day of Indy action.</p>
							<Link to="/indy-fullday" aria-label="indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
        < BmwPcLocations page="half-day" />
			</div>
		</Layout>
	);
};

export default IndyHalfDay;
